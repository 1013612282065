@media screen and (max-width: 575px) {
  .view-all-home-page {
    display: flex;
    flex-direction: row;
    margin-right: 0%;
    width: 90%;
    justify-content: end;
  }
}
@media screen and (min-width: 576px) {
  .view-all-home-page {
    display: flex;
    flex-direction: row;
    margin-right: 9.3%;
    width: 90%;
    justify-content: end;
  }
}
@media screen and (min-width: 768px) {
  .view-all-home-page {
    display: flex;
    flex-direction: row;
    margin-right: 0%;
    width: 90%;
    justify-content: end;
  }
}
@media screen and (min-width: 992px) {
  .view-all-home-page {
    display: flex;
    flex-direction: row;
    margin-right: 21%;
    width: 90%;
    justify-content: end;
  }
}
@media screen and (min-width: 1200px) {
  .view-all-home-page {
    display: flex;
    flex-direction: row;
    margin-right: 9.3%;
    width: 90%;
    justify-content: end;
  }
}
