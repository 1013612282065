@media screen and (max-width: 575px) {
  .shop-button-style {
    border-radius: 8px;
    border: solid 1px #E97101;
    display: flex;
    align-items: center;
    padding-top: 3%;
    padding-bottom: 3%;
    padding-left: 4%;
    padding-right: 4%;
    color: #1b1c1e;
    text-decoration: none !important;
    font-size: 14px;
    width: 98%;
    font-weight: bold;
  }
  .section-1-title{
    font-size: 28px !important;
  }
}
@media screen and (min-width: 576px) {
  .shop-button-style {
    border-radius: 8px;
    border: solid 1px #E97101;
    display: flex;
    align-items: center;
    padding-top: 3%;
    padding-bottom: 3%;
    padding-left: 4%;
    padding-right: 4%;
    color: #1b1c1e;
    text-decoration: none !important;
    font-size: 14px;
    width: 80%;
    font-weight: bold;
  }
  .section-1-title{
    font-size: 24px !important;
  }
}
@media screen and (min-width: 768px) {
  .shop-button-style {
    border-radius: 8px;
    border: solid 1px #E97101;
    display: flex;
    align-items: center;
    padding-top: 3%;
    padding-bottom: 3%;
    padding-left: 4%;
    padding-right: 4%;
    color: #1b1c1e;
    text-decoration: none !important;
    font-size: 14px;
    width: 95%;
    font-weight: bold;
  }
  .section-1-title{
    font-size: 24px !important;
  }
}
@media screen and (min-width: 992px) {
  .shop-button-style {
    border-radius: 8px;
    border: solid 1px #E97101;
    display: flex;
    align-items: center;
    padding-top: 3%;
    padding-bottom: 3%;
    padding-left: 4%;
    padding-right: 4%;
    color: #1b1c1e;
    text-decoration: none !important;
    font-size: 14px;
    width: 75%;
    font-weight: bold;
  }
  .section-1-title{
    font-size: 24px !important;
  }
}
@media screen and (min-width: 1200px) {
  .shop-button-style {
    border-radius: 8px;
    border: solid 1px #E97101;
    display: flex;
    align-items: center;
    padding-top: 3%;
    padding-bottom: 3%;
    padding-left: 4%;
    padding-right: 4%;
    color: #1b1c1e;
    text-decoration: none !important;
    font-size: 14px;
    width: 50%;
    font-weight: bold;
  }
  .section-1-title{
    font-size: 36px !important;
  }
}
