@media screen and (max-width: 575px) {
  .product-service-list-tablet-mobile {
    justify-content: center;
  }
}
@media screen and (min-width: 576px) {
  .product-service-list-tablet-mobile {
    justify-content: center;
  }
}
@media screen and (min-width: 768px) {
  .product-service-list-tablet-mobile {
    justify-content: center;
  }
}
@media screen and (min-width: 992px) {
  .product-service-list-tablet-mobile {
    justify-content: center;
  }
}
@media screen and (min-width: 1200px) {
  .product-service-list-tablet-mobile {
    justify-content: left;
  }
}
