@media screen and (max-width: 575px) {
  .partner-with-us-button {
    border-radius: 8px;
    border: solid 1px #E97101;
    display: flex;
    align-items: center;
    padding: 2%;
    width: 40%;
    justify-content: center;
    text-decoration: none;
  }
}
@media screen and (min-width: 576px) {
  .partner-with-us-button {
    border-radius: 8px;
    border: solid 1px #E97101;
    display: flex;
    align-items: center;
    padding: 2%;
    width: 22%;
    justify-content: center;
    text-decoration: none;
  }
}
@media screen and (min-width: 768px) {
  .partner-with-us-button {
    border-radius: 8px;
    border: solid 1px #E97101;
    display: flex;
    align-items: center;
    padding: 2%;
    width: 22%;
    justify-content: center;
    text-decoration: none;
  }
}
@media screen and (min-width: 992px) {
  .partner-with-us-button {
    border-radius: 8px;
    border: solid 1px #E97101;
    display: flex;
    align-items: center;
    padding: 2%;
    width: 19%;
    justify-content: center;
    text-decoration: none;
  }
}
@media screen and (min-width: 1200px) {
  .partner-with-us-button {
    border-radius: 8px;
    border: solid 1px #E97101;
    display: flex;
    align-items: center;
    padding: 1%;
    width: 12%;
    justify-content: center;
    text-decoration: none;
  }
}
