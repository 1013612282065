@media screen and (max-width: 575px) {
  .footer-style {
    display: flex;
    margin-left: 3%;
    margin-bottom: 6%;
  }
}
@media screen and (min-width: 576px) {
  .footer-style {
    display: flex;
    margin-left: 3%;
    margin-bottom: 6%;
  }
}
@media screen and (min-width: 768px) {
  .footer-style {
    display: flex;
    padding-left: 2%;
    margin-left: 2%;
    margin-bottom: 2%;
  }
}
@media screen and (min-width: 992px) {
  .footer-style {
    display: flex;
    padding-left: 2%;
    margin-left: 2%;
    margin-bottom: 2%;
  }
}
@media screen and (min-width: 1200px) {
  .footer-style {
    display: flex;
    padding-left: 2%;
    margin-left: 2%;
    margin-bottom: 2%;
  }
}
