/* Custom styles for Bootstrap Pagination */
.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .page-item.false .page-link{
    background-color: #F2F2F2 !important;
    color: black !important;
    border: none !important;
  }
  
  .page-item.action.false .page-link {
    background-color: white !important;
    color: #1B1C1E !important;
  }
  
  .page-item.action.disabled .page-link{
    background-color: white !important;
    color: #DADADA !important;
  }
  
  .page-item.active .page-link {
    background-color: black !important;
    color: white !important;
    border: none !important;
  }
  
  /* Apply border to next and previous spans */
  .page-item.action .page-link {
    border: 1px solid #DADADA !important;
  }
  