@media screen and (max-width: 575px) {
  .cart-list-image {
    border-radius: 8px;
    width: 80px;
    height: 80px;
    object-fit: contain;
    border: 1px solid #dadada;
  }
}
@media screen and (min-width: 576px) {
  .cart-list-image {
    border-radius: 8px;
    width: 80px;
    height: 80px;
    object-fit: contain;
    border: 1px solid #dadada;
  }
}
@media screen and (min-width: 768px) {
  .cart-list-image {
    border-radius: 8px;
    width: 100px;
    height: 100px;
    object-fit: contain;
    border: 1px solid #dadada;
  }
}
@media screen and (min-width: 992px) {
  .cart-list-image {
    border-radius: 8px;
    width: 120px;
    height: 120px;
    object-fit: contain;
    border: 1px solid #dadada;
  }
}
@media screen and (min-width: 1200px) {
  .cart-list-image {
    border-radius: 8px;
    width: 100px;
    height: 100px;
    object-fit: contain;
    border: 1px solid #dadada;
  }
}
