@media screen and (max-width: 575px) {
  .product-detail-image {
    object-fit: contain;
    aspect-ratio: 2/1;
  }
  .description-section-for-large {
    display: none;
  }
  .description-section-for-small {
    display: flex;
  }
  .section1-scroll {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    height: auto;
    scrollbar-width: none;
  }
  .section2-scroll {
    overflow-y: auto;
    height: auto;
    scrollbar-width: none;
  }
}
@media screen and (min-width: 576px) {
  .product-detail-image {
    object-fit: contain;
    aspect-ratio: 2/1;
  }
  .description-section-for-large {
    display: none;
  }
  .description-section-for-small {
    display: flex;
  }
  .section1-scroll {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    height: auto;
    scrollbar-width: none;
  }
  .section2-scroll {
    overflow-y: auto;
    height: auto;
    scrollbar-width: none;
  }
}
@media screen and (min-width: 768px) {
  .product-detail-image {
    object-fit: contain;
    aspect-ratio: 2/3;
  }
  .description-section-for-large {
    display: flex;
  }
  .description-section-for-small {
    display: none;
  }
  .section1-scroll {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    height: auto;
    scrollbar-width: none;
  }
  .section2-scroll {
    overflow-y: auto;
    height: auto;
    scrollbar-width: none;
  }
}
@media screen and (min-width: 992px) {
  .product-detail-image {
    object-fit: contain;
    aspect-ratio: 3/2;
  }
  .description-section-for-large {
    display: flex;
  }
  .description-section-for-small {
    display: none;
  }
  .section1-scroll {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    height: 100vh;
    scrollbar-width: none;
  }
  .section2-scroll {
    overflow-y: auto;
    height: 100vh;
    scrollbar-width: none;
  }
}
@media screen and (min-width: 1200px) {
  .product-detail-image {
    object-fit: contain;
    aspect-ratio: 3/2;
  }
  .description-section-for-large {
    display: flex;
  }
  .description-section-for-small {
    display: none;
  }
  .section1-scroll {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    height: 100vh;
    scrollbar-width: none;
  }
  .section2-scroll {
    overflow-y: auto;
    height: 100vh;
    scrollbar-width: none;
  }
}
