@media screen and (max-width: 575px) {
  .mobile-category-section {
    margin-top: 3%;
    height: 15vh;
    overflow-x: auto;
  }
}
@media screen and (min-width: 576px) {
  .mobile-category-section {
    margin-top: 3%;
    height: 15vh;
    overflow-x: auto;
  }
}
@media screen and (min-width: 768px) {
  .mobile-category-section {
    margin-top: 3%;
    height: 20vh;
    overflow-x: auto;
  }
}
@media screen and (min-width: 992px) {
}
@media screen and (min-width: 1200px) {
}
