@media screen and (max-width: 575px) {
  .order-product-list-image {
    border-radius: 8px;
    width: 70px;
    height: 70px;
    object-fit: contain;
    border: 1px solid #dadada;
  }
}
@media screen and (min-width: 576px) {
  .order-product-list-image {
    border-radius: 8px;
    width: 70px;
    height: 70px;
    object-fit: contain;
    border: 1px solid #dadada;
  }
}
@media screen and (min-width: 768px) {
  .order-product-list-image {
    border-radius: 8px;
    width: 60px;
    height: 60px;
    object-fit: contain;
    border: 1px solid #dadada;
  }
}
@media screen and (min-width: 992px) {
  .order-product-list-image {
    border-radius: 12px;
    width: 80px;
    height: 80px;
    object-fit: contain;
    border: 1px solid #dadada;
  }
}
@media screen and (min-width: 1200px) {
  .order-product-list-image {
    border-radius: 12px;
    width: 70px;
    height: 70px;
    object-fit: contain;
    border: 1px solid #dadada;
  }
}
