/* IncrementDecrementBtn.css */

.btn-group,
.add-to-cart-btn {
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 12px !important;
  overflow: hidden;
  border: 1px solid #dadada;
  transition: border 0.3s ease;
}

button {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #e97101;
  background-color: #fff;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
}

p {
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid #f5f8fb;
  border-right: 1px solid #f5f8fb;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.disabled-btn {
  color: #dadada;
}

@media screen and (max-width: 575px) {
  .btn-group {
    width: 70%;
    margin-top: 3%;
    margin-bottom: 3%;
  }
  .add-to-cart-btn {
    width: 100%;
  }
}

/* For md screens */
@media screen and (min-width: 576px) {
  .btn-group {
    width: 70%;
    margin-top: 3%;
    margin-bottom: 3%;
  }
  .add-to-cart-btn {
    width: 100%;
  }
}

/* For md screens */
@media screen and (min-width: 768px) {
  .btn-group {
    width: 70%;
    margin-top: 3%;
    margin-bottom: 3%;
  }
  .add-to-cart-btn {
    width: 70%;
  }
}

/* For lg screens */
@media screen and (min-width: 992px) {
  .btn-group {
    width: 70%;
  }
  .add-to-cart-btn {
    width: 60%;
  }
}

@media screen and (min-width: 1200px) {
  .btn-group {
    width: 40%;
  }
  .add-to-cart-btn {
    width: 60%;
  }
}
