.loading-spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}

.loading-spinner-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.loading-spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  animation: spin 2s linear infinite;
}

.loading-spinner-text {
  margin-left: 10px;
  color: #FF8D24;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 575px) {
  .loading-spinner {
    width: var(--spinner-size, 30px); /* Use custom size variable */
    height: var(--spinner-size, 30px);
  }
}
@media screen and (min-width: 576px) {
  .loading-spinner {
    width: var(--spinner-size, 30px); /* Use custom size variable */
    height: var(--spinner-size, 30px);
  }
}
@media screen and (min-width: 768px) {
  .loading-spinner {
    width: var(--spinner-size, 40px); /* Use custom size variable */
    height: var(--spinner-size, 40px);
  }
}
@media screen and (min-width: 992px) {
  .loading-spinner {
    width: var(--spinner-size, 60px); /* Use custom size variable */
    height: var(--spinner-size, 60px);
  }
}
@media screen and (min-width: 1200px) {
  .loading-spinner {
    width: var(--spinner-size, 60px); /* Use custom size variable */
    height: var(--spinner-size, 60px);
  }
}

.loading-spinner-text {
  @media (max-width: 768px) {
    font-size: 12px;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    font-size: 18px;
  }
  @media (min-width: 1024px) {
    font-size: 24px;
  }
} 
