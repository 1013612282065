.filter-mobile-modal .modal-fullscreen {
  width: 100% !important;
}

@media screen and (max-width: 575px) {
  .filter-mobile-modal-header-text {
    font-size: 18px !important;
    font-weight: 600 !important;
  }
}
@media screen and (min-width: 576px) {
  .filter-mobile-modal-header-text {
    font-size: 18px !important;
    font-weight: 600 !important;
  }
}
@media screen and (min-width: 768px) {
  .filter-mobile-modal-header-text {
    font-size: 24px !important;
    font-weight: 600 !important;
  }
}
@media screen and (min-width: 992px) {
  .filter-mobile-modal-header-text {
    font-size: 24px;
    font-weight: 600;
  }
}
@media screen and (min-width: 1200px) {
  .filter-mobile-modal-header-text {
    font-size: 20px;
    font-weight: 600;
  }
}
