@media screen and (max-width: 575px) {
  .coming-soon-image {
    width: 80% !important;
  }
}
@media screen and (min-width: 576px) {
  .coming-soon-image {
    width: 80% !important;
  }
}
@media screen and (min-width: 768px) {
  .coming-soon-image {
    width: 50% !important;
  }
}
@media screen and (min-width: 992px) {
  .coming-soon-image {
    width: 50% !important;
  }
}
@media screen and (min-width: 1200px) {
  .coming-soon-image {
    width: 30% !important;
  }
}
