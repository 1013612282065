@media screen and (max-width: 575px) {
  .checkout-button-laptop {
    display: none;
  }
  .checkout-button-mobile {
    display: flex;
  }
  .checkout-product-title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 170px;
    width: 70%;
  }
}
@media screen and (min-width: 576px) {
  .checkout-button-laptop {
    display: none;
  }
  .checkout-button-mobile {
    display: flex;
  }
  .checkout-product-title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 170px;
    width: 65%;
  }
}
@media screen and (min-width: 768px) {
  .checkout-button-laptop {
    display: flex;
    margin-top: 1%;
  }
  .checkout-button-mobile {
    display: none;
  }
  .checkout-product-title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 120px;
    width: 65%;
  }
}
@media screen and (min-width: 992px) {
  .checkout-button-laptop {
    display: flex;
    margin-top: 1%;
  }
  .checkout-button-mobile {
    display: none;
  }
  .checkout-product-title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 180px;
    width: 65%;
  }
}
@media screen and (min-width: 1200px) {
  .checkout-button-laptop {
    display: flex;
    margin-top: 1%;
  }
  .checkout-button-mobile {
    display: none;
  }
  .checkout-product-title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 380px;
    width: 80%;
  }
}
