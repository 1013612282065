@media screen and (max-width: 575px) {
  .modal-fullscreen {
    width: 90% !important;
    animation: fadeInRight 1s ease-in-out forwards;
  }
}
@media screen and (min-width: 576px) {
  .modal-fullscreen {
    width: 90% !important;
    animation: fadeInRight 1s ease-in-out forwards;
  }
}
@media screen and (min-width: 768px) {
  .modal-fullscreen {
    width: 60% !important;
    animation: fadeInRight 1s ease-in-out forwards;
  }
}
@media screen and (min-width: 992px) {
  .modal-fullscreen {
    width: 60% !important;
    animation: fadeInRight 1s ease-in-out forwards;
  }
}
@media screen and (min-width: 1200px) {
  .modal-fullscreen {
    width: 40% !important;
    animation: fadeInRight 1s ease-in-out forwards;
  }
}

.modal-fullscreen .modal-content {
  border-radius: 8px !important;
}

.cart-modal-style {
  display: flex !important;
  justify-content: end;
  padding-right: 0px !important;
}

@keyframes fadeInRight {
  from {
    opacity: 1;
    transform: translateX(100%);
  }
  to {
    opacity: 2;
    transform: translateX(0);
  }
}
